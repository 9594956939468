export const baseURL = "https://admin.hqz.app/";
export const secretKey = "aw6xayDawf3cUm22QtMHlxfL8lZESl0W";

//aws credentials
export const ProjectName = "HQZ";
export const bucket_name = "hqz-app";
export const end_point = "s3.us-east-1.amazonaws.com";
export const aws_access_key_id = "AKIA47CRWVBCSXIYS7FV";
export const aws_secret_access_key = "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";
export const folderStructurePath = "uploads";
