import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Admin from "./Component/Pages/Admin";
import Dashboard from "./Component/Pages/dashboard/Dashboard";
import Login from "./Component/Pages/Login";
import PrivateRoute from "../src/Component/util/PrivateRoute";
import Error404 from "./Component/Pages/Error404";
import { useDispatch } from "react-redux";
import "./assets/css/responsive.css";
import { setToken } from "./Component/util/setAuth";
import { LOGIN_ADMIN } from "./Component/store/admin/admin.type";
import ForgotPassword from "./Component/Pages/ForgotPassword";
import SetPassword from "./Component/Pages/SetPassword";
import axios from "axios";
import Registration from "./Component/Pages/Registration";
import Updatecode from "./Component/Pages/Updatecode";

function App() {
  const dispatch = useDispatch();
  const key = localStorage.getItem("key");
  const token = localStorage.getItem("token");
  const [login, setLogin] = useState(true);

  useEffect(() => {
    axios
      .get("admin/login")
      .then((res) => {
        setLogin(res.data.login);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: LOGIN_ADMIN, payload: token });
  }, [setToken, key]);

  const navigate = useNavigate();

  const isAuth = true;

  return (
    <div className="App">
      <Routes>
        
        <Route path="/" element={login ? <Login /> : <Registration />} />
        <Route path="/updateCode" element={<Updatecode />} />
        {!login && <Route path="/login" element={<Login />} />}
        {/* <Route path="/*" element={<Error404 />} /> */}
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/changePassword" element={<SetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
